@import 'src/styles/mixins.scss';

.root {
	margin: 9px auto;
	height: auto;
	z-index: 100;
	width: 100%;
	min-width: 303px;
	text-align: center;
	position: relative;

	&:nth-child(n) {
		&:nth-child(n) {
			iframe {
				position: static !important;
			}

			&:nth-child(n) {
				iframe {
					position: static !important;
				}

				&:nth-child(n) {
					iframe {
						position: static !important;
					}
				}
			}
		}
	}

	@include --mobile {
		overflow-x: scroll;
    max-width: 100vw;
	}
}

.empty {
	display: none;
}

.sticky {
	position: sticky;
	top: 60px;
	margin-top: 0px;
	max-width: 320px;

	@include novaya_europe_theme {
		top: 75px;
	}
}

.static {
	max-width: 320px;
	padding-bottom: 60px;
}

.onTheRight {
	margin: 9px 0;
	text-align: right;
	margin-left: auto;
}

.container {}
