@import 'src/styles/mixins.scss';

.root {
	margin: 0 0 20px;
	color: var(--whiteColor);
	font-weight: 900;
	text-transform: uppercase;
	font-size: 15px;
	line-height: 13px;
	letter-spacing: 0.06em;
	white-space: nowrap;

	@include novaya-constructor-theme {
		font-weight: 700;
		margin-bottom: 0;
	}

	@include constructor-footer {
		letter-spacing: unset;
	}
	@include --tablet-horizontal-max {
		font-size: 12px;
		line-height: 10px;
		margin-bottom: 15px;
	}
}
