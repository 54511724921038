.checkboxWrapper {
  cursor: pointer;
}

.checkbox {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  margin: 0 14px 0 0;
  vertical-align: middle;

  span {
    display: none;
    width: 9px;
    height: 9px;
  }
}

.primary {
  border: 1px solid var(--darkColor);

  span {
    background: #2D2D2D;
  }
}

.rightCheckbox {
  margin: 0 0 0 14px;
}

.input {
  display: none;

  &:checked + .checkbox span {
    display: block;
  }
}

.withoutChildren {
  margin: 0;
}
