@import 'src/styles/mixins.scss';

@mixin constructor-footer-contacts-column {
	:global(.constructor-footer-contacts-column) & {
		@content;
	}
}

.root {
	padding-top: 5px;
	height: auto;
	position: relative;
	width: 200px;
	@include novaya-constructor-theme {
		padding-top: 25px;
	}
}

.wide {
	@include constructor-footer-contacts-column {
		width: 175px;
	}
}

.listContatcs {
	list-style-type: none;
	padding-left: 0;

	@include novaya-constructor-theme {
		margin-block-start: 10px;
	}

	@include --desktop-max {
		li {
			columns: 2;
			padding-right: 0;
			@media (max-width: 360px) {
				padding-right: 10px;
			}
			@include europe-columns {
				columns: 1;
			}
		}
		.itemBaltia {
			columns: 1;
		}
	}
	@media screen and (max-width: 410px) {
		li {
			columns: 1;
		}
	}
}
.item {
	margin-bottom: 5px;
	a {
		color: var(--textColorFooter);
		@include novaya-constructor-theme {
			color: var(--secondColor);
		}
		font-size: 15px;
		transition: color 0.3s ease;
		will-change: color;
		font-weight: 400;
		display: block;
		line-height: 20px;

		&:hover {
			color: var(--whiteColor);
		}
		@media (max-width: 362px) {
			font-size: 13px;
		}
	}
	h2 {
		position: relative;
	}
}

.textRoot {
	a {
		color: var(--textColorFooter);
		font-size: 15px;
		line-height: 20px;
		transition: color 0.3s ease;
		will-change: color;
		font-weight: 400;
		display: block;

		&:hover {
			color: var(--whiteColor);
		}
		@media (max-width: 362px) {
			font-size: 13px;
		}
	}
	h2 {
		position: relative;
	}
}

.text {
	margin: 0 0 20px;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	color: var(--textColorFooter);
	@include novaya-constructor-theme {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: var(--secondColor);
		margin-bottom: 10px;
	}
	@media (max-width: 362px) {
		font-size: 13px;
	}
}

.marginTop {
	margin: 20px 0 0;
	@include novaya-constructor-theme {
		margin: 10px 0 0;
	}
}

@include --desktop-max {
	.root {
		width: 200px;
		display: inline-flex;
		flex-direction: column;
		margin-bottom: 35px;

		@include novaya-constructor-theme {
			margin-bottom: 0;
		}
	}
	.wide {
		width: 390px;
		display: flex;
		& > h2 {
			text-align: center;
		}
		@include constructor-footer-contacts-column {
			width: 390px;
		}
		@include europe-columns {
			padding-top: 5px;
			height: auto;
			position: relative;
			width: 200px;
			display: block;
			& > h2 {
				text-align: left;
			}
		}
		@media screen and (max-width: 410px) {
			width: 200px;
			@include constructor-footer-contacts-column {
				width: 200px;
			}
			& > h2 {
				text-align: left;
			}
		}
	}

	.columnBaltia {
		width: 100%;
		margin-bottom: 30px;
		text-align: center;
		@media screen and (max-width: 410px) {
			& > h2 {
				text-align: center;
			}
		}
	}
}
