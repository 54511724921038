@import 'src/styles/mixins.scss';

.root {
	position: relative;
	p {
		margin: 0;
		font-size: 18px;
		line-height: 21px;

		@include --desktop-max {
			font-size: 16px;
			line-height: 21px;
		}
	}

	&::after {
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: var(--mainColor);
		left: -19px;
		top: 8px;
	}
}

.isBlackDot {
	&::after {
		background-color: var(--darkColor);
		opacity: 1 !important;
	}
}

.isImportant p {
	font-weight: 700;
}

.lasted::after {
	opacity: 0.7;
}
.middle::after {
	opacity: 0.4;
}
.old::after {
	opacity: 0.1;
}
