@import 'src/styles/mixins.scss';

.root {
	display: flex;
	justify-content: center;
	margin: 9px 0;
	position: relative;
	@media (max-width: 1365px) {
		padding: 0 15px;
	}

	@include --desktop-max {
		background-color: #fff;
		padding: 0;
		margin-left: -10px;
		margin-right: -10px;
		border-bottom: 1px solid #d8d8d8;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
		&::after,
		&::before {
			position: absolute;
			content: '';
			width: 20px;
			top: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 90%);
		}

		&::before {
			right: unset;
			left: 0;
			z-index: 2;
			background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, white 90%);
		}
	}
}

.isFisrtLine {
	@include --desktop-max {
		margin-top: 0;
	}
}

.container {
	max-width: 1356px;
	width: 100%;
	display: flex;
	margin: 0 5px;
	overflow: hidden;
	overflow-x: auto;
	&::-webkit-scrollbar {
		display: none;
	}
	@include --desktop-max {
		margin: 0;
	}
}

.hauling {
	padding: 16px 14px;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.78px;
	position: relative;
	white-space: nowrap;

	&::after {
		position: absolute;
		content: '';
		height: 18px;
		width: 1px;
		background-color: #d0d0d0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	@include --desktop-max {
		font-size: 13px;
		line-height: 15px;
		padding: 17px 14px;
	}
}

.isFirstHauling {
	padding-left: 0;
	@include --desktop-max {
		padding-left: 15px;
	}
}

.isLastHauling {
	&::after {
		display: none;
	}
	@include --desktop-max {
		padding-right: 15px;
	}
}
