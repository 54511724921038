.socials {
    list-style: none;
	padding: 0;
	margin: 0;
    display: flex;
	align-items: center;
}

.small img {
	height: 24px;
	width: 24px;
}

.big img {
	height: 36px;
	width: 36px;
}
