@import 'src/styles/mixins.scss';

.root {
	border-top: 1px solid var(--darkColor);
	@include dark-theme {
		border-top: 1px solid var(--borderColorDarkTheme);
	}

	@media print {
		display: none;
	}
}
.social {
	display: none;
}

.hr {
	border: 0;
	display: none;
	margin: 40px 0;
	width: 100%;
	height: 1px;
	background-color: var(--stripesColor);

	@include --tablet-vertical-only {
		&:last-of-type {
			display: block;
			clear: both;
		}
	}
	@include --tablet-vertical-max {
		display: block;
		clear: both;
		margin: 20px 0;
	}
}
.backy {
	position: relative;
	height: 100%;
	width: 100%;
	background: var(--darkColor);
}
