@import 'src/styles/mixins.scss';

.root {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20000000;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
}

.overlay {
	background-color: rgba(0, 0, 0, 0.8);
	min-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.container {
	background-color: var(--whiteColor);
	position: relative;
	max-width: 760px;
	padding: 60px;
	width: 100%;
}

.success {
	font-size: 27px;
	font-weight: 900;
	letter-spacing: 0.01em;
	line-height: 25px;
	text-align: center;

	span,
	small {
		display: block;
	}

	small {
		font-size: 21px;
		font-weight: 500;
		line-height: 25px;
		margin-top: 5px;
	}
}

.close {
	width: 18px;
	height: 18px;
	position: absolute;
	top: 20px;
	right: 20px;

	&::before,
	&::after {
		content: '';
		display: block;
		width: 24px;
		height: 2px;
		background-color: var(--darkColor);
		transition: background-color 0.3s ease;
		will-change: background-color;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -1px;
		margin-left: -12px;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}

	&:hover::before,
	&:hover::after {
		background-color: var(--mainColor);
	}
}

.title {
	font-size: 21px;
	font-weight: 500;
	line-height: 25px;
	margin-bottom: 22px;
}

.text {
	font-family: var(--sansSerif);
	margin-bottom: 15px;
	font-size: 18px;
	line-height: 25px;

	strong {
		font-weight: 300;
		color: var(--secondaryColor);
	}
}

.input {
	clear: both;
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 0;
	border: 1px solid;
	border-color: var(--stripesColor);
	font-size: 13px;
	line-height: 50px;
	padding: 0 20px;
	margin-bottom: 15px;
}

.submit {
	float: right;
	width: 170px;
	height: 50px;
	border-radius: 3px;
	color: var(--whiteColor);
	font-size: 12px;
	font-weight: 900;
	letter-spacing: 0.06em;
	line-height: 52px;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	background-color: var(--darkColor);
}

.textarea{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-bottom: 15px;
}
