@import 'src/styles/mixins.scss';
@import '../../../mixins.scss';

.picWrapper {
	width: auto;
	height: auto;
	position: relative;
	@include columnist-card {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
	}
	@include columnist-card-constructor {
		background-color: #b3b0ab;
		background-size: 250%;
		background-position: center center;
	}

	@include types-one {
		width: 163%;
		@include --desktop-max {
			width: auto;
		}
	}
}
.pic {
	width: 100%;
	height: 100%;
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;

	@include columnist-card {
		background-position: center bottom;
		background-size: 50%;
	}
	@include types-one {
		padding-bottom: 56.25%;
	}
	@include types-two {
		padding-bottom: 56.25%;
	}
	@include types-three {
		padding-bottom: 56.25%;
	}
	@include types-four {
		padding-bottom: 56.25%;
	}
	@include main-topic {
		padding-bottom: 56.25%;
	}
	@include firstElem-in-types-five {
		padding-bottom: 56.25%;
	}
}

.emptyPic {
	display: none;
}

.picWrapperAnimated {
	min-height: 480px;
	max-width: 849.78px;
	width: 100%;
	min-width: auto;
	@media screen and (max-width: 1365px) {
		width: auto;
		max-width: 711px;
		min-height: 400px;
	}
	@media screen and (max-width: 1200px) {
		width: auto;
		max-width: 657.7px;
		min-height: 370px;
	}
	@media screen and (width: 1024px) {
		width: auto;
		max-width: 586.5px;
		min-height: 330px;
	}
}
