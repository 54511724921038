@import 'src/styles/mixins.scss';

.root {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1000000;
	width: 100%;
	height: 100%;
}

@include --mobile {
	.root {
		padding: 10px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
}

.overlay {
	position: fixed;
	top: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	width: 100%;
	height: 100%;
}

.content {
	width: 100%;
	max-width: 460px;
	text-align: center;
	background-color: var(--whiteColor);
	position: absolute;
	top: calc(50% + 24px);
	left: 50%;
	transform: translate(-50%, -50%);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;

	@include dark-theme {
		background-color: var(--darkColor);
	}
}

@media screen and (max-width: 460px) {
	.content {
		width: 97%;
	}
}

.contentBody {
	position: relative;
	width: 100%;
	height: 100%;
}
.title {
	font-size: 21px;
	font-weight: 500;
	line-height: 25px;
	margin-bottom: 20px;
}

.text {
	font-size: 17px;
	font-weight: 500;
	line-height: 20px;
	margin-bottom: 30px;
	white-space: normal;
}

.button {
	background-color: var(--mainColor);
	width: 220px;
	height: 40px;
	border-radius: 3px;
	display: block;
	margin: 0 auto;
	font-weight: 900;
	color: var(--whiteColor);
	text-transform: uppercase;
	text-align: center;
	font-size: 12px;
	line-height: 40px;
	letter-spacing: 0.7px;
	white-space: nowrap;
}

.close {
	top: -30px !important;
}

@include --mobile {
	.root .close {
		display: none;
	}
}

.header {
	white-space: nowrap;
	text-align: left;
}

.type {
	font-weight: 900;
	font-size: 20px;
	line-height: 20px;
	letter-spacing: 0.09px;
	display: inline-block;
	vertical-align: top;
	transition: color 0.3s ease 0s;
	position: absolute;
	top: -47px;
	color: var(--whiteColor);
	background: var(--textColor);
	width: 50%;
	height: 48px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	right: 0;
	z-index: -1;
	max-width: 400px;

	@include dark-theme {
		color: var(--whiteColor);
		background: var(--textColor);
	}

	&:not(:last-child) {
		left: 0;
		right: unset;
	}

	&.active,
	&:hover {
		color: var(--darkColor);
		background: var(--whiteColor);
		@include dark-theme {
			color: var(--whiteColor);
			background: var(--darkColor);
		}
	}
}

@include --mobile {
	.type {
		font-size: 20px;
		line-height: 20px;
	}
}

.forgot {
	font-weight: 900;
	font-size: 20px;
	line-height: 20px;
	letter-spacing: 0.09px;
	display: inline-block;
	vertical-align: top;
	transition: color 0.3s ease 0s;
	position: absolute;
	top: -56px;
	color: var(--whiteColor);
	max-width: 600px;
	background: linear-gradient(
		to bottom,
		var(--textColor) 0%,
		var(--textColor) 100%,
		white 35%,
		white 100%
	);
	width: 100%;
	height: 60px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	right: 0;
	cursor: default !important;
}

