@import 'src/styles/mixins.scss';

.novaya_europe_theme {
	--mainColor: #0e8e7e;
	--darkColor: #181e2c;
	--lighterSecond: #f6f7f8;
	--borderColorDarkTheme: #303541;
	--textColorFooter: #a6acbc;
	--lightDarkColor: #79829b;
	--textColor: #161b2b;
	--linkColor: #4d5979;
	--secondaryColor: #3ea598;
	--darkBlueColor: #182342;
	--gradient: linear-gradient(90deg, rgba(14, 142, 126, 1) 0%, rgba(17, 99, 89, 1) 50%);
}

.novaya_constructor_theme {
	--lighterSecond: #f7f7f7;
}

.mr7_theme {
	--mainColor: #ff7911;
	--darkColor: #353535;
	--borderColor: rgba(53, 53, 53, 0.2);
	--secondaryColor: #325f82;
	--gradient: linear-gradient(90deg, #ff7911 0%, #ee5f00 50%);
}

.additionalAds {
	width: 970px;
	margin: 0 auto;
	height: 250px;
	display: none;
}

.additionalAdsContainer {
	width: 100%;
	background-color: #2e3033;
}

.root {
	min-height: 100%;
}

.preloader {
	overflow: hidden;
	z-index: 900000;
	position: fixed;
	margin: 0 auto;
	background-color: var(--darkColor);
	will-change: opacity, width, height;
	opacity: 0;
	width: 0;
	height: 0;
	transition: opacity 0.3s ease 0s, width 0s ease 0.3s, height 0s ease 0.3s;

	&.visible {
		transition: opacity 0.3s ease 0s, width 0s ease 0s, height 0s ease 0s;
		opacity: 0.96;
		width: 100%;
		height: 100%;
	}
}

.stopper {
	height: 100vh;
	background-image: var(--blueDots);
	background-position: center center;
}

/**
 * Counters block
 */

.counters {
	border-top: 1px solid var(--stripesColor);
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	max-width: var(--desktopMaxWidth);
	margin: 0 auto;
	width: 100%;
	display: none;

	@include --tablet-horizontal-only {
		max-width: var(--tabletHorizontalMaxWidth);
	}

	@include --tablet-vertical-only {
		max-width: var(--tabletVerticalMaxWidth);
	}

	@include --mobile {
		max-width: none;
	}
}
