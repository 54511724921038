@mixin single-news-with-news-block {
	:global(.single-news-with-news-block) & {
		@content;
	}
}

/* Mixins for Types */

@mixin types-one {
	:global(.types-one) & {
		@content;
	}
}
@mixin types-two {
	:global(.types-two) & {
		@content;
	}
}
@mixin types-three {
	:global(.types-three) & {
		@content;
	}
}
@mixin types-four {
	:global(.types-four) & {
		@content;
	}
}
@mixin dark-theme-item-in-Types {
	:global(.dark-theme-item-in-Types) & {
		@content;
	}
}

/* GroupBlock */

@mixin dark-theme-group-block {
	:global(.dark-theme-group-block) & {
		@content;
	}
}
@mixin white-theme-group-block {
	:global(.white-theme-group-block) & {
		@content;
	}
}
@mixin clear-theme-group-block {
	:global(.clear-theme-group-block) & {
		@content;
	}
}
@mixin blue-theme-group-block {
	:global(.blue-theme-group-block) & {
		@content;
	}
}
@mixin green-theme-group-block {
	:global(.green-theme-group-block) & {
		@content;
	}
}
@mixin lilac-theme-group-block {
	:global(.lilac-theme-group-block) & {
		@content;
	}
}
@mixin group-block {
	:global(.group-block) & {
		@content;
	}
}

/* CityBlock */

@mixin city-block {
	:global(.city-block) & {
		@content;
	}
}

/* MainTopic */

@mixin main-topic {
	:global(.main-topic) & {
		@content;
	}
}
@mixin main-item-in-main-topic {
	:global(.main-item-in-main-topic) & {
		@content;
	}
}
@mixin dark-theme-main-topic {
	:global(.dark-theme-main-topic) & {
		@content;
	}
}

/* SocialsBlock */

@mixin socials-block-style {
	:global(.socials-block-style) & {
		@content;
	}
}

/* FiveBlock */

@mixin five-block {
	:global(.five-block) & {
		@content;
	}
}
@mixin firstElem-in-types-five {
	:global(.firstElem-in-types-five) & {
		@content;
	}
}
@mixin otherElements-in-types-five {
	:global(.otherElements-in-types-five) & {
		@content;
	}
}

/* Сolumnist */

@mixin columnist-card {
	:global(.columnist-card) & {
		@content;
	}
}

@mixin columnist-card-constructor {
	:global(.columnist-card-constructor) & {
		@content;
	}
}

/* co-participant-style*/

@mixin types-co-participant-style {
	:global(.types-co-participant-style) & {
		@content;
	}
}

@mixin blocked-article {
	:global(.blocked-article) & {
		@content;
	}
}



@mixin main-page-mr7 {
	:global(.main-page-mr7) & {
		@content;
	}
}