@import 'src/styles/mixins.scss';

.root {
	background-color: var(--darkColor);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	padding-bottom: 60px;
}
.container {
	width: 100%;
	max-width: 970px;
}

.text {
	border-top: 1px solid var(--secondColor);
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	margin-block-start: 0;
	margin-block-end: 0;
	padding-top: 21px;
	color: var(--secondColor);
	a {
		font-weight: 700;
		color: var(--secondColor);
		&:hover {
			color: var(--mainColor);
		}
	}
}

.cooperation {
	&_title {
		margin: 10px 0 0;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: var(--secondColor);
	}
	a {
		color: var(--secondColor);
		font-size: 15px;
		line-height: 20px;
		transition: color 0.3s ease;
		will-change: color;
		font-weight: 400;
		display: block;

		&:hover {
			color: var(--whiteColor);
		}
		@media (max-width: 362px) {
			font-size: 13px;
		}
	}
}

.navigation {
	display: flex;
	margin: 20px auto 40px;
	justify-content: space-between;

	@include --desktop-max {
		flex-direction: column;
		align-items: center;
		&_column {
			display: flex;
			flex-direction: column;
		}
	}
}

.marginTop {
	margin: 20px 0 0;
}

.logo {
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		height: 50px;
		width: 81px;
	}
}
