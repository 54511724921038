@import 'src/styles/mixins.scss';

.root {
	padding-top: 65px;
	position: relative;

	&::before {
		z-index: 10;
		width: 100%;
		height: 150px;
		/*position: absolute 0 * * 0;*/
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		content: '';
		background-image: var(--blueDots);
	}

	@include --mobile {
		padding-top: 20px;
	}
}

.container {
	background-color: var(--whiteColor);
	z-index: 20;
	position: relative;
	max-width: 1220px;
	margin: 0 auto;

	@include --tablet-horizontal-max {
		max-width: 970px;
	}

	@include --tablet-vertical-max {
		max-width: 650px;
	}
}
