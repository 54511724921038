@import 'src/styles/mixins.scss';

.notFound {
	width: 100%;
	min-height: 70vh;
	background-image: var(--blueDots);
	background-color: var(--whiteColor);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--darkColor);
	border-bottom: 1px solid var(--borderColorWhiteTheme);
	@include dark-theme {
		background-color: var(--darkColor);
		background-image: var(--grayDots);
		color: var(--whiteColor);
		border-bottom: 1px solid var(--borderColorDarkTheme);
	}

	@include mr7_theme {
		background-image: none;
		min-height: 60vh;
	}
}

.notFound_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px 40px;
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 4px;

	@include dark-theme {
		background-color: var(--transparentWhiteColor);
	}
	@include --desktop-max {
		width: 70%;
	}
	@include --mobile-max {
		width: auto;
		margin: auto 15px;
		padding: 10px 20px;
	}
}

.notFound_status {
	margin: 0;
	font-weight: 900;
	font-size: 120px;
	letter-spacing: 0.01em;
	line-height: 100px;
	@include novaya-constructor-theme {
		font-weight: 700;
	}

	@include mr7_theme {
		@media (min-width: 1024px) {
			font-size: 150px;
		}
	}

	@include --mobile {
		font-size: 80px;
		line-height: 60px;
	}
}

.notFound_message {
	font-weight: 900;
	font-size: 21px;
	letter-spacing: 0.02em;
	line-height: 40px;
	text-transform: uppercase;

	@include --mobile {
		font-size: 13px;
		line-height: 20px;
	}
}

.notFound_regular {
	font-weight: 400;
	max-width: 710px;
	margin: 30px 30px 0;
	text-align: justify;

	@include mr7_theme {
		@media (min-width: 1024px) {
			font-size: 30px;
			line-height: 32px;
			max-width: 800px;
			margin-top: 60px;
			margin-bottom: 60px;
		}
	}
	@include --mobile {
		text-align: left;
		font-size: 18px;
	}
	@include --mobile-max {
		margin: 30px 0 0;
	}
}
