@import 'src/styles/mixins.scss';

.popup {
  position: fixed;
  top: 200%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  border: 2px solid var(--mainColor);
  border-radius: 15px;
  transition: top .3s;
  padding: 50px;
  z-index: 100;
  width: 100%;
  max-width: 642px;
  min-width: 320px;

  @include --mobile {
    padding: 15px;
    max-width: 375px;
  }
}

.close {
  position: absolute;
  top: 28px;
  right: 26px;
  width: 27px;
  height: 27px;
  cursor: pointer;
  z-index: 10;
}

.open {
  top: 50%;
}
