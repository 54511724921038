@import 'src/styles/mixins.scss';

.root {
	list-style: none;
	padding: 0;
	margin: 0;
	color: #353535;
}

.district {
	font-size: 15px;
	line-height: 48px;
	letter-spacing: 0.03em;
	cursor: pointer;
	&:hover {
		color: #ff7911;
		@include --not-support-hover {
			color: #353535;
		}
	}
}

.selected {
	font-weight: 700;
	position: relative;

	img {
		position: absolute;
		width: 14px;
		height: 14px;
		left: -23px;
		top: 17px;
	}
}
