@import 'src/styles/mixins.scss';

.root {
	display: flex;
	flex-flow: wrap;
	margin: 0 auto;
	position: relative;
	width: 100%;
	max-width: 1366px;

	@include --container-max {
		padding: 0 15px;
	}
	@include --desktop-max {
		padding: unset;
	}
}

.center {
	justify-content: center;
}

.block {
	display: block;
}

.row {
	flex-flow: row;
}
