@import 'src/styles/mixins.scss';
@import 'src/components/Base/PictureOfTheDay/mixins.scss';

@mixin types-co-participant-style {
	:global(.types-co-participant-style) & {
		@content;
	}
}

.button {
	height: 39px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	background-color: var(--mainColor);
	padding: 0 30px;
	span {
		color: var(--darkColor);
		text-transform: uppercase;
	}
}

.yellow {
	background-color: var(--donateColor);
}

.constructor {
	font-family: var(--robotoFlex);
	min-width: 237px;
	span {
		font-size: 15px;
		font-weight: 600;
	}
	@include types-co-participant-style {
		height: 44px;
		width: 217px;
		min-width: unset;
		position: absolute;
		top: -22px;
		left: 50%;
		z-index: 101;
		transform: translateX(-50%);
		padding: 0;
		@include types-three {
			height: 40px;
			width: 179px;
			top: -25px;
			span {
				font-size: 12px;
			}
		}

		@include --mobile-max {
			width: 200px;
			span {
				font-size: 14px;
			}
		}

		@media screen and (max-width: 355px) {
			height: 39px;
			width: 170px;
			span {
				font-size: 12px;
			}
		}
	}
}

.europe {
	color: var(--whiteColor);
	padding: 0 20px;
	span {
		color: var(--whiteColor);
		line-height: 12px;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 0.04em;
	}
}
