.add {
	height: 45px;
	border-radius: 20px;
	padding: 0 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-family: var(--sansSerif);
	span {
		color: var(--whiteColor);
		font-weight: 600;
		font-size: 15px;
		text-transform: uppercase;
		line-height: 22px;
	}
}

.primary {
	background: var(--mainColor);
}

.secondary {
	background: #cccccc;
}

.darkGrey {
	background: #4D505C;
}

.loading {
	height: 24px;
}
