@import 'src/styles/mixins.scss';

.root {
	margin-top: 30px;
	display: flex;

	@include --container-max {
		margin-left: 100px;
	}

	@include --desktop-min {
		flex: 0 0 300px;
	}

	@include --tablet-horizontal-only {
		flex: 0 0 280px;
	}

	@include --tablet-vertical-max {
		display: none;
	}
}
