@import 'src/styles/mixins.scss';

.root {
	a {
		color: var(--mainColor);
		@include novaya_europe_theme {
			color: #050b28;
		}
	}
	.labelcbx {
		user-select: none;
		cursor: pointer;
		margin-bottom: 0;
		display: flex;
		align-items: center;
	}
	.labelcbx input:checked + .checkbox {
		border-color: var(--mainColor);
		@include novaya_europe_theme {
			border-color: #050b28;
		}
	}
	.labelcbx input:checked + .checkbox svg path {
		fill: var(--mainColor);
		@include novaya_europe_theme {
			fill: #050b28;
		}
	}
	.labelcbx input:checked + .checkbox svg polyline {
		stroke-dashoffset: 0;
	}
	.labelcbx:hover .checkbox svg path {
		stroke-dashoffset: 0;
	}
	.labelcbx .checkbox {
		position: relative;
		top: 2px;
		float: left;
		margin-right: 8px;
		min-width: 20px;
		min-height: 20px;
		border: 2px solid var(--lightestSecond);
		border-radius: 3px;
		@include novaya_europe_theme {
			border: 2px solid #b3b5bd;
		}
	}
	.labelcbx .checkbox svg {
		position: absolute;
		top: -2px;
		left: -2px;
	}
	.labelcbx .checkbox svg path {
		fill: none;
		stroke: var(--mainColor);
		stroke-width: 2;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-dasharray: 71px;
		stroke-dashoffset: 71px;
		transition: all 0.6s ease;
		@include novaya_europe_theme {
			stroke: #050b28;
		}
	}
	.labelcbx .checkbox svg polyline {
		fill: none;
		stroke: var(--whiteColor);
		stroke-width: 2;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-dasharray: 18px;
		stroke-dashoffset: 18px;
		transition: all 0.3s ease;
	}
	.labelcbx > span {
		pointer-events: none;
		vertical-align: middle;
	}

	.invisible {
		position: absolute;
		z-index: -1;
		width: 0;
		height: 0;
		opacity: 0;
	}
}
