.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: rgba(33, 34, 38, 0.6);
  z-index: 1300;
  transition: .3s opacity;
  pointer-events: none;
}

.open {
  opacity: 1;
  pointer-events: auto;
}
