@import 'src/styles/mixins.scss';

.root {
	background-color: var(--darkColor);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 15px;

	&_container {
		width: 100%;
		max-width: 1008px;
	}

	&_text {
		border-top: 1px solid var(--secondColor);
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		margin-block-start: 0;
		margin-block-end: 0;
		padding-top: 21px;
		color: var(--secondColor);
		span {
			font-weight: 700;
		}
		a {
			color: var(--secondColor);
			&:hover {
				color: var(--mainColor);
			}
		}
	}

	&_navigation {
		display: flex;
		width: 910px;
		padding-left: 80px;
		margin: 0 auto;
		margin-top: 20px;
		justify-content: space-between;

		&_first {
			display: flex;
			min-width: 490px;
			justify-content: space-between;
		}
		@include --desktop-max {
			flex-direction: column;
			width: 100%;
			max-width: 420px;
			padding-left: 0;
			&_first {
				min-width: unset;
			}
			&_second {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		@media screen and (max-width: 450px) {
			&_first {
				flex-direction: column;
				&_one,
				&_two {
					align-items: center;
					display: flex;
					flex-direction: column;
				}
			}
		}
	}
}

.logo {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 54px;
	margin-bottom: 95px;
	img {
		opacity: 0.6;
		width: 57px;
		height: 37px;
	}
	span {
		font-style: normal;
		font-weight: 800;
		font-size: 15px;
		line-height: 13px;
		color: var(--secondColor);
		width: 67px;
		display: block;
		margin-left: 15px;
	}
}
