@import 'src/styles/mixins.scss';

.root {
	text-align: left;
	margin: 0 auto;
	max-width: 300px;
	padding: 15px 0 25px;

	label {
		font-size: 13px;
		color: var(--lightDarkColor);
	}
}

.input {
	display: block;
	width: 100%;
	height: 34px;
	border-bottom: 1px solid var(--stripesColor);
	font-size: 20px;
	line-height: 29px;
	margin-bottom: 15px;
	@include dark-theme {
		border-bottom: 1px solid var(--borderColorDarkTheme);
		color: var(--whiteColor);
	}
}

@include --mobile {
	.input {
		font-size: 15px;
	}
}

.footer {
	margin-top: 25px;

	p {
		font-family: var(--sansSerif);
		font-size: 13px;
		font-weight: 400;
		line-height: 17px;
		color: var(--lightDarkColor);
		white-space: normal;
		margin-bottom: 20px;
	}

	a {
		color: var(--mainColor);
	}
}

.submit {
	position: relative;
	display: block;
	margin: 0 auto;
	width: 100%;
	height: 40px;
	color: var(--whiteColor);
	font-weight: 900;
	text-transform: uppercase;
	text-align: center;
	font-size: 12px;
	line-height: 40px;
	letter-spacing: 0.7px;
	white-space: nowrap;
	max-width: 400px;

	span {
		border-radius: 20px;
		background-color: var(--mainColor);
		display: block;
		width: 100%;
		transition: opacity 0.3s ease 0s;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.pending {
		span {
			opacity: 0;
		}

		.preloader {
			opacity: 1;
		}
	}
}

.forgot {
	font-size: 13px;
	line-height: 40px;
	color: var(--lightDarkColor);
	display: block;
	padding-top: 15px;
	margin: 0 auto;
}

@include --mobile {
	.forgot {
		font-size: 15px;
	}
}

.socials {
	margin: 20px auto 0;
}

.caption {
	white-space: normal;
	font-weight: 600;
	color: var(--lightDarkColor);
	font-size: 13px;
	line-height: 20px;
	text-align: center;
	text-transform: uppercase;
	position: relative;

	p {
		background: var(--whiteColor);
		max-width: 210px;
		margin: 0 auto;
		position: inherit;
		font-size: 15px;
		font-weight: 900;
		color: var(--lightDarkColor);

		@include dark-theme {
			background: var(--darkColor);
		}
	}

	hr {
		position: absolute;
		top: -3px;
		width: 100%;
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #cccccc;
		margin: 1em 0;
		padding: 0;
	}
}

@include --mobile {
	.caption {
		font-size: 15px;
	}
}

.list {
	overflow: hidden;
	width: 100%;
	height: 85px;
	margin-top: 25px;
}

.item {
	cursor: pointer;
	border-radius: 20px;
	// width: 48.5%;
	width: 100%;
	height: 40px;
	position: relative;
	display: inline-block;

	p {
		font-size: 15px;
		position: absolute;
		left: 49%;
		height: 24px;
		color: var(--whiteColor);
		line-height: 40px;
	}

	&:not(:last-child) {
		margin-right: 3%;
	}

	&.facebook {
		background-color: #3b5998;
	}

	&.vkontakte {
		background-color: #4c75a3;
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 36px;
		height: 36px;
		fill: var(--whiteColor);
		margin-left: calc(36px / 2 * -1);
		margin-top: calc(36px / 2 * -1);
	}
}

.googleAuth {
	display: flex;
	justify-content: center;
	opacity: 1;

	& div {
		color: transparent;
	}
}
