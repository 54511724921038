@import 'src/styles/mixins.scss';

.container {
	width: 100%;
	background-color: var(--lighterSecond);

	@include dark-theme {
		background-color: var(--darkColor);
	}

	@include mr7-theme {
		background-color: var(--whiteColor);
	}
}

.print {
	background-color: var(--whiteColor) !important;
}
