@import 'src/styles/mixins.scss';

@mixin constructor-footer-slim-column {
	:global(.constructor-footer-slim-column) & {
		@content;
	}
}

.root {
	padding-top: 5px;
	height: auto;
	position: relative;
	width: 200px;
	@include novaya-constructor-theme {
		padding-top: 25px;
	}

	@include constructor-footer-slim-column {
		padding-right: 60px;
	}
}

.notShowOnDesktop {
	display: none;
}

.list {
	list-style-type: none;
	padding-left: 0;
	@include novaya-constructor-theme {
		margin-block-start: 10px;
	}
}

.newitem {
	margin-bottom: 5px;

	&::after {
		content: 'new';
		color: var(--whiteColor);
		font-size: 13px;
		font-weight: 900;
		margin-left: 8px;
		background: var(--mainColor);
		display: inline-block;
		padding: 0 7px 1px;
		border-radius: 6px;
		text-transform: none;

		@include novaya-europe-theme {
			content: 'en';
		}

		@media (max-width: 375px) {
			position: absolute;
		}
	}
}
.item {
	margin-bottom: 5px;
	a {
		color: var(--textColorFooter);
		@include novaya-constructor-theme {
			color: var(--secondColor);
		}
		font-size: 15px;
		transition: color 0.3s ease;
		will-change: color;
		font-weight: 400;
		display: block;
		line-height: 20px;
		@include constructor-footer {
			font-size: 14px;
			font-weight: 500;
		}

		&:hover {
			color: var(--whiteColor);
		}
		@media (max-width: 362px) {
			font-size: 13px;
		}
	}
	h2 {
		position: relative;
	}
}

.text {
	margin: 0 0 20px;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	color: var(--textColorFooter);
	@include novaya-constructor-theme {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: var(--secondColor);
		margin-bottom: 10px;
	}
	@include constructor-footer {
		font-size: 14px;
		font-weight: 500;
	}
	@media (max-width: 362px) {
		font-size: 13px;
	}
}
.marginTop {
	margin: 20px 0 0;
	@include novaya-constructor-theme {
		margin: 10px 0 0;
	}
}

.textRoot {
	a {
		color: var(--textColorFooter);
		font-size: 15px;
		line-height: 20px;
		transition: color 0.3s ease;
		will-change: color;
		font-weight: 400;
		display: block;

		&:hover {
			color: var(--whiteColor);
		}
		@media (max-width: 362px) {
			font-size: 13px;
		}
	}
	h2 {
		position: relative;
	}
}

.highlighted {
	font-weight: 500;
	color: var(--mainColor);
	@include novaya-constructor-theme {
		color: inherit;
	}
}

@include --desktop-max {
	.root,
	.mobileHide {
		width: 200px;
		display: inline-flex;
		flex-direction: column;
		margin-bottom: 35px;
	}
	.root {
		@include novaya-constructor-theme {
			margin-bottom: 0;
		}
		@include constructor-footer-slim-column {
			padding-right: 0;
		}
	}

	.wide {
		width: 390px;
		display: flex;
		& > h2 {
			text-align: center;
		}
		@include europe-columns {
			padding-top: 5px;
			height: auto;
			position: relative;
			width: 200px;
			display: block;
			& > h2 {
				text-align: left;
			}
		}
		@media screen and (max-width: 410px) {
			width: 200px;
			& > h2 {
				text-align: left;
			}
		}
	}
	.columnBaltia {
		width: 33%;
		text-align: center;
		@media screen and (max-width: 424px) {
			width: 90px;
		}
	}
}

@include --desktop-max {
	.mobileShow {
		display: none;
	}
}
