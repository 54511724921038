 @keyframes swirl-in-fwd {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
  
.swirl-in-fwd {
    animation: swirl-in-fwd 0.2s ease-out both;
}

@keyframes swirl-out-bck {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.swirl-out-bck {
    animation: swirl-out-bck 0.2s ease-in both;
}