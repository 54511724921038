@mixin --mobile-max {
	@media screen and (max-width: 509px) {
		@content;
	}
}

@mixin --desktop-max {
	@media screen and (max-width: 1023px) {
		@content;
	}
}

@mixin --desktop-container-only {
	@media (min-width: 1023px) and (max-width: 1365px) {
		@content;
	}
}

@mixin --container-max {
	@media screen and (max-width: 1365px) {
		@content;
	}
}

@mixin --tablet-only {
	@media screen and (min-width: 721px) and (max-width: 1200px) {
		@content;
	}
}

@mixin --tablet-vertical-min {
	@media screen and (min-width: 721px) {
		@content;
	}
}

@mixin --tablet-vertical-only {
	@media screen and (max-width: 960px) and (min-width: 721px) {
		@content;
	}
}

@mixin --tablet-vertical-max {
	@media screen and (max-width: 960px) {
		@content;
	}
}

@mixin --tablet-horizontal-min {
	@media screen and (min-width: 961px) {
		@content;
	}
}

@mixin --tablet-horizontal-only {
	@media screen and (max-width: 1200px) and (min-width: 961px) {
		@content;
	}
}

@mixin --mobile {
	@media screen and (max-width: 720px) {
		@content;
	}
}

@mixin --desktop-min {
	@media screen and (min-width: 1200px) {
		@content;
	}
}
@mixin --vote-510px {
	@media screen and (max-width: 510px) {
		@content;
	}
}

@mixin --vote-min {
	@media screen and (max-width: 414px) {
		@content;
	}
}

@mixin --tablet-horizontal-max {
	@media screen and (max-width: 1200px) {
		@content;
	}
}

@mixin --not-support-hover {
	@media (hover: none) {
		@content;
	}
}

@mixin dark-theme {
	:global(.dark-user-theme) & {
		@content;
	}
}

@mixin dark-theme-article {
	:global(.dark-theme-article) & {
		@content;
	}
}
@mixin petition-material {
	:global(.petition-material) & {
		@content;
	}
}

@mixin novaya-europe-theme {
	:global(.novaya-europe-theme) & {
		@content;
	}
}

@mixin novaya-constructor-theme {
	:global(.novaya-constructor-theme) & {
		@content;
	}
}
@mixin novaya-media-theme {
	:global(.novaya-media-theme) & {
		@content;
	}
}
@mixin mr7-theme {
	:global(.mr7-theme) & {
		@content;
	}
}

@mixin donate-block-europe {
	:global(.donate-block-europe) & {
		@content;
	}
}
@mixin support-block-europe {
	:global(.support-block-europe) & {
		@content;
	}
}

// for Footer
@mixin europe-columns {
	:global(.europe-columns) & {
		@content;
	}
}
@mixin baltia-columns {
	:global(.baltia-columns) & {
		@content;
	}
}

@mixin constructor-footer {
	:global(.constructor-footer) & {
		@content;
	}
}

// Stripe payment

@mixin donate-stripe {
	:global(.donate-stripe) & {
		@content;
	}
}

@mixin donate-overlay-form {
	:global(.donate-overlay-form) & {
		@content;
	}
}
@mixin donate-article-block-europe-slim {
	:global(.donate-article-block-europe-slim) & {
		@content;
	}
}



@mixin constructor-main-page {
	:global(.constructor-main-page) & {
		@content;
	}
}
@mixin mr7-main-page {
	:global(.mr7-main-page) & {
		@content;
	}
}

@mixin article-list-item {
	:global(.article-list-item) & {
		@content;
	}
}

@mixin overlay-search {
	:global(.overlay-search) & {
		@content;
	}
}

@mixin user-subscriptions-updates {
	:global(.user-subscriptions-updates) & {
		@content;
	}
}

@mixin overlay-search-constructor {
	:global(.overlay-search-constructor) & {
		@content;
	}
}

// Profile

@mixin profile-my-subscriptions {
	:global(.profile-my-subscriptions) & {
		@content;
	}
}

/* Recommendations */

@mixin recommendation-block {
	:global(.recommendation-block) & {
		@content;
	}
}

@mixin recommendations-block-firstBlock {
	:global(.recommendations-block-firstBlock) & {
		@media screen and (min-width: 721px) {
			@content;
		}
	}
}
@mixin article-recommendations-block {
	:global(.article-recommendations-block) & {
		@content;
	}
}



/* email block */

@mixin email-block-in-picday {
	:global(.email-block-in-picday) & {
		@content;
	}
}
