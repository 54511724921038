@import 'src/styles/mixins.scss';

.addButton {
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--mainColor);
	border-radius: 50%;
	position: relative;

	@include recommendation-block {
		@media screen and (max-width: 1600px) and (min-width: 1366px), (max-width: 360px) {
			position: absolute;
			top: -86px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 101;
		}
	}
	@include article-recommendations-block {
		@media screen and (max-width: 360px) {
			position: absolute;
			top: -86px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 101;
		}
	}
	@include recommendations-block-firstBlock {
		position: absolute;
		top: -66px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 101;
	}

	img {
		width: 18px;
		height: 18px;
	}
	&:disabled {
		cursor: default;
	}
}

.grey {
	background: #ccc;
}

.darkGrey {
	background: #4c4f5b;
	img {
		transform: rotate(45deg);
	}
}

.md {
	width: 44px;
	height: 44px;
	@include article-recommendations-block {
		width: 42px;
		height: 42px;
	}
}

.sm {
	width: 36px;
	height: 36px;
}

.wrapper {
	width: min-content;
	height: min-content;
	position: relative;
	.addButton {
		z-index: 2 !important;
	}
}

.circle,
.circle2 {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1px;
	height: 1px;
	border: 0px solid #e6eef5;
	border-radius: 50%;
	pointer-events: none;
	box-sizing: content-box;
	opacity: 0;
	z-index: 0;
}

.visible {
	.circle,
	.circle2 {
		animation: move 3s infinite linear;
	}
	.circle2 {
		animation: move 3s infinite linear;
		animation-delay: 1.5s;
	}
}

@keyframes move {
	0% {
		border-width: 0;
		opacity: 0;
	}
	50% {
		opacity: 1;
		border-width: 75px;
	}
	100% {
		opacity: 0;
		border-width: 150px;
	}
}
