@import 'src/styles/mixins.scss';

@mixin baltia-preloader {
	:global(.baltia-preloader) & {
		@content;
	}
}
@mixin standart-preloader {
	:global(.standart-preloader) & {
		@content;
	}
}
@mixin loader-light-color {
	:global(.loader-light-color) & {
		@content;
	}
}

.root {
	width: 64px;
	height: 64px;
	overflow: hidden;
	position: absolute;
	top: 50%;
	right: 50%;
	background-color: var(--mainColor);

	@include baltia-preloader {
		background-color: var(--darkColor);
	}

	margin-top: calc(-1 * (64px / 2));
	margin-right: calc(-1 * (64px / 2));

	@include standart-preloader {
		background-color: transparent;
		width: 44px;
		height: 44px;
		margin-top: calc(-1 * (44px / 2));
		margin-right: calc(-1 * (44px / 2));
	}

	&.small {
		width: 32px;
		height: 32px;
		margin-top: calc(-1 * (32px / 2));
		margin-right: calc(-1 * (32px / 2));
	}
}

@keyframes vertical-move {
	100% {
		transform: translateY(-64px);
	}
}

@keyframes vertical-move-small {
	100% {
		transform: translateY(-32px);
	}
}

@keyframes horizontal-move {
	100% {
		transform: translateX(-64px);
	}
}

@keyframes horizontal-move-small {
	100% {
		transform: translateX(-32px);
	}
}

.left {
	width: 12px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 12px;
	animation: vertical-move 0.7s infinite ease-in-out;

	&::after,
	&::before {
		content: '';
		margin: 12px 0;
		display: block;
		width: 100%;
		height: 40px;
		background-color: var(--whiteColor);
	}

	&::after {
		margin-top: 24px;
	}

	.small & {
		animation: vertical-move-small 0.7s infinite ease-in-out;
		width: 6px;
		left: 6px;

		&::after,
		&::before {
			margin: 6px 0;
			height: 20px;
		}

		&::after {
			margin-top: 12px;
		}
	}
}

.center {
	width: 100%;
	height: 12px;
	position: absolute;
	top: 26px;
	right: 0;
	font-size: 0;
	white-space: nowrap;
	animation: horizontal-move 0.7s infinite ease-in-out;

	&::after,
	&::before {
		content: '';
		display: inline-block;
		vertical-align: top;
		margin-left: 28px;
		margin-right: 22px;
		width: 14px;
		height: 14px;
		background-color: var(--whiteColor);
	}

	.small & {
		animation: horizontal-move-small 0.7s infinite ease-in-out;
		height: 6px;
		top: 13px;

		&::after,
		&::before {
			margin-left: 14px;
			margin-right: 11px;
			width: 7px;
			height: 7px;
		}
	}
}

.right {
	width: 12px;
	height: 100%;
	position: absolute;
	top: 0;
	left: 12px;
	animation: vertical-move 0.7s infinite ease-in-out;
	animation-direction: reverse;

	&::after,
	&::before {
		content: '';
		display: block;
		width: 100%;
		height: calc(26px * 2);
		margin: 12px 0 0;
		background-color: var(--whiteColor);
	}

	.small & {
		animation: vertical-move-small 0.7s infinite ease-in-out;
		animation-direction: reverse;
		width: 6px;
		right: 6px;

		&::after,
		&::before {
			height: 26px;
			margin-top: 6px;
		}
	}
}

:global .MuiCircularProgress-root{
	color: #10182c !important;
	width: 44px !important;
	height: 44px !important;
	@include loader-light-color {
		color: var(--secondColor) !important;
	}
	@include dark-theme {
		color: var(--secondColor) !important;
	}
}

:global .MuiCircularProgress-circle{
	stroke-width: 2 !important;
}
