.tooltip {
  display: none;
  position: fixed;
  z-index: 2000;
  max-width: 350px;
  width: 100%;
  padding: 17px 22px;
  border: 5px solid var(--mainColor);
  background: var(--whiteColor);
  border-radius: 5px;
  font-size: 17px;
  line-height: 21px;
  color: #4D505C;
}

.visible {
  display: block;
}

.tooltipWrapper {
  position: relative;
}
