@import 'src/styles/mixins.scss';

.root {
	flex: 1 1;
	display: flex;
	flex-direction: column;
}

.col-4 {
	@include --tablet-vertical-min {
		flex-basis: calc((var(--desktopColumn) * 4) + (var(--desktopGutter) * 3));
		margin-right: var(--desktopGutter);
	}

	@include --tablet-horizontal-min {
		&:nth-child(3n) {
			margin-right: 0;

			&::after {
				display: none;
			}
		}
	}

	@include --tablet-only {
		flex-basis: calc((var(--tabletColumn) * 4) + (var(--tabletGutter) * 3));
	}

	@include --tablet-vertical-only {
		flex-basis: calc((var(--tabletColumn) * 5) + (var(--tabletGutter) * 3));

		&:nth-child(2n),
		&:last-child {
			margin-right: 0;

			&::after {
				display: none;
			}
		}

		&:last-child:nth-child(odd) {
			flex-basis: 100%;
			padding: 35px 0 0;
			margin-top: 35px;

			&.light {
				border-top: 1px solid var(--transparentWhiteColor);
			}

			&.dark {
				border-top: 1px solid;
				border-color: var(--borderColor);
			}
		}
	}

	@include --mobile {
		flex-basis: 100%;

		&:not(:last-child) {
			padding-bottom: 19px;
			margin-bottom: 20px;

			&.bordered.dark {
				border-bottom: 1px solid;
				border-color: var(--borderColor);
			}

			&.bordered.light {
				border-bottom: 1px solid var(--transparentWhiteColor);
			}
		}
	}
}

.col-3 {
	@include --tablet-vertical-min {
		flex-basis: calc((var(--desktopColumn) * 3) + (var(--desktopGutter) * 2));
		margin-right: var(--desktopGutter);
	}

	@include --tablet-horizontal-min {
		&:nth-child(4n) {
			margin-right: 0;

			&::after {
				display: none;
			}
		}
	}

	@include --tablet-horizontal-only {
		flex-basis: calc((var(--tabletColumn) * 3) + (var(--tabletGutter) * 2));
	}

	@include --tablet-vertical-only {
		flex-basis: calc((var(--tabletColumn) * 5) + (var(--tabletGutter) * 3));

		&:nth-child(2n),
		&:last-child {
			margin-right: 0;

			&::after {
				display: none;
			}
		}
	}

	@include --mobile {
		flex-basis: 100%;

		&:not(:last-child) {
			padding-bottom: 19px;
			margin-bottom: 20px;

			&.bordered.dark {
				border-bottom: 1px solid;
				border-color: var(--borderColor);
			}

			&.bordered.light {
				border-bottom: 1px solid var(--transparentWhiteColor);
			}
		}
	}
}

.bordered {
	@include --tablet-vertical-min {
		position: relative;

		&::after {
			content: '';
			display: block;
			width: 1px;
			height: 100%;
			/*position: absolute 0 -20px * *;*/
			position: absolute;
			top: 0;
			left: -20px;
		}
	}
}

.dark::after {
	background-color: var(--borderColor);
}

.light::after {
	background-color: var(--transparentWhiteColor);
}
