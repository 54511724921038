@import 'src/styles/mixins.scss';

.root {
	text-align: left;
	margin: 0 auto;
	max-width: 90%;
	padding: 15px 0 25px;

	label {
		font-size: 13px;
		color: var(--lightDarkColor);
	}
	iframe {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto !important;
	}
}

.input {
	display: block;
	width: 100%;
	height: 34px;
	border-bottom: 1px solid var(--stripesColor);
	font-size: 20px;
	line-height: 29px;
	margin-bottom: 15px;
	@include dark-theme {
		border-bottom: 1px solid var(--borderColorDarkTheme);
		color: var(--whiteColor);
	}

	@include --mobile {
		font-size: 15px;
	}
}

.footer {
	margin-top: 25px;

	p {
		font-family: var(--sansSerif);
		font-size: 13px;
		font-weight: 400;
		line-height: 17px;
		color: var(--lightDarkColor);
		white-space: normal;
		margin-bottom: 20px;
	}

	a {
		color: var(--mainColor);
	}
}

.submit {
	position: relative;
	display: block;
	margin: 0 auto;
	width: 100%;
	height: 40px;
	color: var(--whiteColor);
	font-weight: 900;
	text-transform: uppercase;
	text-align: center;
	font-size: 12px;
	line-height: 40px;
	letter-spacing: 0.7px;
	white-space: nowrap;
	max-width: 400px;

	span {
		border-radius: 20px;
		background-color: var(--mainColor);
		display: block;
		width: 100%;
		transition: opacity 0.3s ease 0s;
		position: absolute;
		top: 0;
		left: 0;
	}

	.preloader {
		transition: opacity 0.3s ease 0s;
		opacity: 0;
	}

	&.disabled {
		cursor: not-allowed;

		span {
			background-color: rgba(0, 172, 229, 0.5);
		}
	}

	&.pending {
		span {
			opacity: 0;
		}

		.preloader {
			opacity: 1;
		}
	}
}
