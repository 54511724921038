@import 'src/styles/mixins.scss';

.root {
	background-color: var(--mainColor);
	text-transform: uppercase;
	font-weight: 700;
	font-size: 16px;
	color: var(--whiteColor);
	height: 39px;
	width: 157px;
	margin: 18px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 52px;
	letter-spacing: 0.03em;
}
