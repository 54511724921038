@import 'src/styles/mixins.scss';

.root {
	display: flex;
	align-items: center;

	span {
		font-size: 14px;
		color: #666b7a;
		margin-right: 16px;
		letter-spacing: 0.78px;
	}
}

.active span {
	color: #050b28;
}